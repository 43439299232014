// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/images/main_banner.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (min-width: 993px){\n    .main-banner {background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size: auto 100%; background-color : #1e0506 !important;}\n}", "",{"version":3,"sources":["webpack://./components/hp/hp.css"],"names":[],"mappings":"AAAA;IACI,cAAc,yDAA8C,CAAC,0BAA0B,EAAE,qCAAqC,CAAC;AACnI","sourcesContent":["@media (min-width: 993px){\n    .main-banner {background-image: url(/images/main_banner.jpg);background-size: auto 100%; background-color : #1e0506 !important;}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
