import $ from 'jquery';
import './hp.css';


(function($){
var tabs_info_wrapper= $('.tabs-info-wrapper');
var benefits_wrapper= $('.benefits-wrapper');
var live_rates_wrapper= $('#live-rates-widget');
var live_rates_table = $('.lr-table-wrapper'),
loadingLiveRates = false,
liveRatesLoaded = false;




function doScrolling() {
    if (!loadingLiveRates && !liveRatesLoaded && live_rates_wrapper.length > 0 && window.isInViewport(live_rates_wrapper)) {
        loadingLiveRates = true;
        !liveRatesLoaded && import(/* webpackChunkName: "liveRatesWidget" */'../liveRatesWidget/liveRatesWidget')
            .then(() => {
                loadingLiveRates = false;
                liveRatesLoaded = true;
            });
    }
    if (tabs_info_wrapper.html().trim() === '' && typeof window.isInViewport != 'undefined' && window.isInViewport(tabs_info_wrapper)) {
        import(/* webpackChunkName: "tabs" */'../tabs/tabs');
    }
    if (benefits_wrapper.html().trim() === '' && typeof window.isInViewport != 'undefined' && window.isInViewport(benefits_wrapper)) {
        import(/* webpackChunkName: "benefits" */'../benefits/benefits');
    }
}
window.scrollingTasks.push(doScrolling);


if ( $(window).width() < 992  ){
    live_rates_wrapper.insertAfter( $( ".s2" ) );
}else{
    if (!loadingLiveRates && !liveRatesLoaded && live_rates_wrapper.length > 0 && window.isInViewport(live_rates_wrapper)) {
        loadingLiveRates = true;
        !liveRatesLoaded && import(/* webpackChunkName: "liveRatesWidget" */'../liveRatesWidget/liveRatesWidget')
            .then(() => {
                loadingLiveRates = false;
                liveRatesLoaded = true;
            });
        }
    }



})($);